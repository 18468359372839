@import '../../../css/methods.scss';

iframe{
  width: 320px !important;
  height: 550px !important;
}

.call-notes {
  margin-top: 24px !important;
  min-width: 500px !important;
}

.campaign-select {
  margin-bottom: 24px !important;
}

// .dialer {
//   /*position: fixed;*/
//   float: right;
//   bottom: 0;
//   right: 0%;
//   width: 100%;
//   height: 100%;
//   background: $lt_panel;

//   overflow: hidden;

//   .amazon-connect,
//   .phonebook-list {
//     position: relative;
//     float: left;
//     display: flex;
//     height: 100%;
//     width: 100%;

//     @include border-radius(0.3vmax);
//     background: white;

//     .init {
//       position: absolute;
//       float: left;
//       height: 100%;
//       width: 100%;
//       background: #ffffff;

//       p {
//         font-family: 'Montserrat', sans-serif;
//         font-weight: normal;
//         font-size: 0.8vmax;
//         color: $lt_color;
//         @include textCenter;
//       }
//     }
//   }

//   .amazon-connect {
//     position: absolute;
//     float: left;
//     height: calc(465px - 0.1vmax);
//     width: 102.5% !important;
//     background: $blue;

//     @include border-radius(0.3vmax);

//     .containerDiv {
//       width: 30%;
//       top: 1vmax;

//     }
//   }

//   .phonebook-list {
//     position: relative;
//     float: left;
//     height: calc(100% - 465px - 0.1vmax);
//     width: 100%;
//   }
// }
