@import '../../../css/methods.scss';

.tbl-row {
    background-color: #373737;
    line-height: 1.43;
}

.tbl-cell {
  color: #ffffff !important;
  font-family: $font !important;
  font-weight: $cwregular;
  font-size: 14px !important;
  padding: 0px !important;

}
.tbl-cell-first {
  color: #ffffff !important;
  font-family: $font !important;
  font-weight: $cwregular;
  font-size: 14px;
  padding: 5px,0px, 0px, 0px !important;


}
.topoffset {
  padding-top: 20px;
}
