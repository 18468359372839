/**
 * The CSS in this file is for styling the demo page,
 * Meny's critical styles (such as transforms) are applied
 * via JavaScript.
 *
 * See the documentation here: https://github.com/hakimel/meny#meny
 *
 * @author Hakim El Hattab | http://hakim.se
 */

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  background-color: #222;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGklEQVQIW2NkYGD4D8SMQAwGcAY2AbBKDBUAVuYCBQPd34sAAAAASUVORK5CYII=);
  background-repeat: repeat;

  font-family: 'Lato', Helvetica, sans-serif;
  font-size: 16px;
  color: #222;
}

a {
  color: #c2575b;
  text-decoration: none;

  -webkit-transition: 0.15s color ease;
  -moz-transition: 0.15s color ease;
  -ms-transition: 0.15s color ease;
  -o-transition: 0.15s color ease;
  transition: 0.15s color ease;
}
a:hover {
  color: #f76f76;
}

h1,
h2 {
  font-size: 24px;
}

.meny {
  display: none;
  padding: 20px;
  overflow: auto;
  background: #333;
  color: #eee;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.meny ul {
  margin-top: 10px;
}
.meny ul li {
  display: inline-block;
  width: 200px;
  list-style: none;
  font-size: 20px;
  padding: 3px 10px;
}
.meny ul li:before {
  content: '-';
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.2);
}

/**
 * Hint graphic that appears while menu is inactive
 */
.meny-arrow {
  position: absolute;
  z-index: 10;

  border: 10px solid transparent;

  -webkit-transition: opacity 0.4s ease 0.4s;
  -moz-transition: opacity 0.4s ease 0.4s;
  -ms-transition: opacity 0.4s ease 0.4s;
  -o-transition: opacity 0.4s ease 0.4s;
  transition: opacity 0.4s ease 0.4s;
}
.meny-left .meny-arrow {
  left: 14px;
  top: 50%;
  margin-top: -16px;
  border-left: 16px solid #333;
}
.meny-right .meny-arrow {
  right: 14px;
  top: 50%;
  margin-top: -16px;
  border-right: 16px solid #333;
}
.meny-top .meny-arrow {
  left: 50%;
  top: 14px;
  margin-left: -16px;
  border-top: 16px solid #333;
}
.meny-bottom .meny-arrow {
  left: 50%;
  bottom: 14px;
  margin-left: -16px;
  border-bottom: 16px solid #333;
}
.meny-active .meny-arrow {
  opacity: 0;

  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -ms-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

/**
 * Main contents area
 */
.contents {
  background: #eee;
  padding: 20px 40px;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  -webkit-overflow-scrolling: touch;
  -webkit-transform-style: preserve-3d;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.contents > article {
  max-width: 400px;
}
.contents p {
  margin: 10px 0 10px 0;
  font-size: 16px;
  line-height: 1.32;
}
.contents small {
  display: block;
  margin-top: 10px;
  padding-top: 10px;
  color: #333;
  font-size: 0.85em;
  border-top: 1px dashed #ccc;

  -webkit-text-size-adjust: none;
}
.contents .sharing {
  position: absolute;
  bottom: 20px;
}
