@mixin tf-x {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
@mixin tf-y {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@mixin tf-xy {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@mixin textCenter {
  position: absolute;
  float: left;
  margin: 0;
  padding: 0;
  width: 80%;
  text-align: center;
  @include tf-xy;
  @include ellipsis;
}
::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-track {
  display: none;
}
::-webkit-scrollbar-track-piece {
  display: none;
}
::-webkit-scrollbar-thumb {
  display: none;
}
::-webkit-scrollbar-corner {
  display: none;
}
::-webkit-resizer {
  display: none;
}
@mixin hor-splitter {
  position: relative;
  float: left;
  width: 90%;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin-top: 1vw;
  margin-bottom: 1vw;
  @include tf-x;
}
@mixin hor-splitter-no-margin {
  position: relative;
  float: left;
  width: 90%;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  @include tf-x;
}
@mixin ellipsis {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
@mixin wrap-text-bulletproof {
  word-break: break-all;
}
@mixin transitionColor {
  -webkit-transition: color 0.1s ease-in-out;
  -moz-transition: color 0.1s ease-in-out;
  -ms-transition: color 0.1s ease-in-out;
  transition: color 0.1s ease-in-out;
}
@mixin transitionBackground {
  -webkit-transition: background 0.1s ease-in-out;
  -moz-transition: background 0.1s ease-in-out;
  -ms-transition: background 0.1s ease-in-out;
  transition: background 0.1s ease-in-out;
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}
@mixin box-shadow($x, $y, $z, $i, $color) {
  -webkit-box-shadow: $x $y $z $i $color;
  -moz-box-shadow: $x $y $z $i $color;
  -ms-box-shadow: $x $y $z $i $color;
  box-shadow: $x $y $z $i $color;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap');

$font: 'Montserrat', sans-serif;


$cwsemibold: 600;
$cwbold: 800;
$cwregular: 500;
$cwlight: 300;

$cwblack: #000000;
$cwwhite: #ffffff;

$cwblue: #00afe9;
$cwyellow: #ffdd19;
$cwpurple: #986fb0;
$cwpink: #ed3c80;
$cworange: #f7921e;
$cwgreen: #56b46a;



$button_blue: #5469fe;
$blue: #385fdd;

$dt_background: #131418;
$dt_panel: #191a1f;
$lt_background: #f2f5f8;
$lt_panel: #e9edf1;

$lt_color: #262626cc;
$dt_color: #ffffffcc;
