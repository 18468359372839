.share-link {
  float: left;
}

.share-button-array {
  float: right;
}

.share-button-array button {
  background-color: green;

}
.share-button-array .MuiButton-label {
  color: white !important;
}
