@import '../../../css/methods.scss';

@import '../../../css/react-grid-layout/css/styles.css';
@import '../../../css/react-resizable/css/styles.css';

.TileBorder {
  width: 100%;
  height: 100%;
}
.TileContent {
  width: 100%;
  height: 100%;
}

.react-grid-item {
  border: 1px solid grey;
  overflow: auto;
    -webkit-box-shadow: 3px 4px 5px 0px rgba(0,0,0,0) !important;
  -moz-box-shadow: 3px 4px 5px 0px rgba(0,0,0,0) !important;
  box-shadow: 3px 4px 5px 0px rgba(0,0,0,0)!important;
  background-color: #ffffff;
  border-radius: 10px;
}

.db-container{
  position: relative;
  float: left;
  height: 100%;
  width: 100%;

}

.grid-wrapper .header {
  display: none;

}
.grid-wrapper .component-title {
  display: block;
}
.change-dashboard {

  position: fixed;
      top: 30px;
      right: 50%;

}
.content-wrapper-td {
  padding-top: 120px;
  padding-left: 80px;
  min-height: 100%;
}

.timer-bar {
  height: 5px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%
}
.timer-bar-inner {
  height: 5px;

  width: 0px;
  background-color: red;
}

.boardviewer .content-wrapper-td {
  padding-left: 10px;
}
