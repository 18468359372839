@import '../../../css/methods.scss';

@import '../../../css/react-grid-layout/css/styles.css';
@import '../../../css/react-resizable/css/styles.css';

.TileBorder {
  width: 100%;
  height: 100%;
}
.TileContent {
  width: 100%;
  height: 100%;
}

.react-grid-item {
  border: 1px solid grey;
  overflow: auto;
  -webkit-box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75) !important;
-moz-box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75) !important;
box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75)!important;
background-color: #ffffff;
}

.db-container{
  position: relative;
  float: left;
  height: 100%;
  width: 100%;

}

.grid-wrapper .header {
  display: none;

}
.grid-wrapper .component-title {
  display: block;
}

.sf_iframe {
  border: none !important;
  width: 44vw !important;
}