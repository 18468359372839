@import '../../../css/methods.scss';

.settings-header {
    padding-left: 20px;
    font-weight: bold !important;
}

.settings-icon {
    margin-right: 5px;
    color: black !important;
}

.settings-list {
    overflow-y: auto;
    height: 100vh;
    background-color: #FFFFFF;
}

.settings-text {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-size: 13px !important;
    color: #545b64 !important;
    font-weight: 550 !important;
    line-height: 20px !important;
    letter-spacing: 0.8px !important;
}

.admin-form {
    position: fixed;
    margin: auto;
    left: 20%;
    top: 20%;
    width: fit-content;
    min-width: 90vw;
}

.content-wrapper-td {
    padding-top: 100px;
    padding-left: 80px;
    min-height: 100%;
}

.settings-paper {
    position: fixed !important;
    width: 15%;
}

.settings-component {
    height: 100px !important;
    overflow-y: scroll !important;
}