@import "../../../methods.scss";

.change-dashboard .MuiSelect-root {
  font-family: $font;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 3px;

}

.change-dashboard .MuiInput-underline::before {
  border-bottom: 0px;

}

.change-dashboard .MuiInputBase-input {
  border-bottom-color: white !important;
  margin-right: 20px;
}

.change-dashboard svg {
  color: white !important;
  height: 30px;
  width: 30px;
  margin-top: -4px;
  margin-left:10px;
}
