@import "../../../methods.scss";

.widget-module.light .line-item h2 {
  color: $cwblack;
}

.widget-module.dark .line-item h2 {
  color: $cwwhite;
}

.widget-module.light {
  background-color: $cwwhite;
}

.widget-module.dark  {
  background-color: $cwblack;
}
