/* structural CSS only */

.react-grid-item h2 {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}

.timefmt-number {
    position: absolute;
      width: 100%;
      text-align: center;
      height: 80%;

      display: flex;
      align-items: center;
      justify-content: center;

}

.timefmt-queue {
  padding-left: 5px;

}

.react-grid-item[max-height~="1800px"] .timefmt-number {
font-size:400px;

}

.react-grid-item[max-height~="1800px"] h2 {
font-size:50px;

}

.react-grid-item[max-height~="1500px"] .timefmt-number {
font-size:300px;

}

.react-grid-item[max-height~="1200px"] .timefmt-number {
  font-size:200px;
}

.react-grid-item[max-height~="1200px"] h2 {
font-size:32px;

}


.react-grid-item[max-height~="900px"] .timefmt-number {
font-size:100px;

}

.react-grid-item[max-height~="400px"] .timefmt-number {
  font-size:70px;
}

.react-grid-item[max-width~="400px"] .timefmt-number {
  font-size:70px;  
}

.react-grid-item[max-height~="400px"] h2 {
  font-size:20px;
}

.react-grid-item[max-height~="300px"] .timefmt-number {
  font-size:50px;
}

.react-grid-item[max-width~="300px"] .timefmt-number {
  font-size:50px;  
}

.react-grid-item[max-height~="300px"] h2 {
  font-size:15px;
}

.react-grid-item[max-height~="200px"] .timefmt-number {
  font-size:40px;
}

.react-grid-item[max-width~="200px"] .timefmt-number {
  font-size:40px;  
}

.react-grid-item[max-height~="200px"] h2 {
  font-size:10px;
}

