@import '../../../css/methods.scss';

nav {
  position: fixed;
  height: 100%;
  width: 80px;
  left:0;
  z-index: 1;
  background-color: black;
  padding-top: 30px;
  top: 0px;


}

nav a {
  color: #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  margin-top: 15px !important;
  padding: 20px;
  width: 100%;
  display: block;
  margin: auto;
  /* margin: 0 auto !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 12px;
}

.nav-active-1 {
  background-color: $cwpurple;
}
.nav-active-2 {
  background-color: $cwyellow;
}

.nav-active-3 {
  background-color: $cwblue;
}

.nav-active-4 {
  background-color: $cwpink;
}

.nav-active-5 {
  background-color: $cwgreen;
}

.nav-active-6{
  background-color: $cwgreen;
}






.sidebar {
  /*position: relative;
    float: left;
    height: 100vh;
    width: 3.5vw;
    min-width: 45px;
    background: $lt_panel;*/
  height: 100%;
  width: 100%;
  .logo {
    position: relative;
    float: left;
    width: 80%;
    height: auto;

    img {
      position: absolute;
      float: left;

      height: 80%;
      width: 80%;

      @include tf-xy;
    }
  }

  .dashboard-icon {
    color: #ffffff;
    margin-left: 17px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
