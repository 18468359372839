@import "../../../methods.scss";

.dashboard-lock {
  position: absolute;
  top: 35px;
  right: 30px;
  color: $cwgreen;
}

.dashboard-lock svg {
  height: 30px;
  width: 30px;
}
