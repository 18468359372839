@import '../../../css/methods.scss';

.tool-bar {
  position: fixed;
    right: 0;
    top: 98px;

    background-color:white;

    .tool {
      width: 100%;
      height: 60px;
      width: 60px;
      padding-top: 12px;
      padding-left: 18px;
      position: absolute;
      right: 0px;
    }
    .tool1 {
      background-color: $cworange;
      color: white;
    }
    .tool-open {
      opacity: 0.5;
    }

}


.connector {
  position: fixed;
    right: 0;
    top: 98px;
    width: 320px;
    height: 550px;
    margin-right: 60px;
    -webkit-box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75) !important;
  -moz-box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75) !important;
  box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75)!important;
  background-color: #ffffff;
}
