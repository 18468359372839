@import "../../../css/methods.scss";

* {
  outline: 0;
}

.wb-container {
  position: relative;
  float: left;
  height: 100%;
  width: 100%;


  .stat-1 {
    background-color: #999999;
    box-shadow: 0px 0px 0px 0px;
  }
  .stat-2 {
    background-color: #F2F2F2;
    box-shadow: 0px 0px 0px 0px;
  }


  header {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }

  .MuiTabs-root {
    background: #f7f7ff;
    color: $blue;
  }

  .statistics-container {
    box-sizing: border-box;
    margin: 10px;
    padding: 15px;
    box-shadow: 0px 0px 0px 0px;
    text-align:center;

    .statistics1 {
      height: 30px;
      width: 100%;
      padding-left: 20px;
      font-size: 20px;
      font-weight: $cwregular;
margin-bottom: 35px;
      align-items: center;
      box-shadow: 0px 0px 0px 0px;

    }

    .green {
      color: green;
    }
  }
}
.change-button {
  display:inline;

  float: right;
  background-color: lightgrey;
  color: white !important;
}

@media only screen and (max-width: 671px) {
    .statistics3 {
        font-size: 12px !important;
    }
    .statistics2 {
      font-size: 40px !important;
    }
}
