@import "../../../methods.scss";

.form-wrapper {
  background-color: #3a404e;
  padding: 50px;
  position: relative;
  font-family: $font;
  border-radius: 15px;

}
.form-wrapper h2 {
  width: 100%;
  text-align: center;
  font-family: $font;
  font-weight: 600;
}
.form-wrapper input {
  background-color: #3a404e;
  color: white;
  font-family: $font;
}

.form-wrapper .MuiFormLabel-root {
  font-family: $font;
}

.form-wrapper .MuiButtonBase-root {

    font-family: $font;
}

.form-wrapper .MuiInput-underline:after {
border-bottom:0px;
color: white;
}
.form-wrapper .MuiInputLabel-root {
z-index: 1;
  color: white;
}

.add-db-item {
  margin-top:20px;
  margin-bottom:20px;

}

.form-wrapper .Mui-focused {
  color: white !important;
}

.form-wrapper .MuiButton-root span {
  color: #ffffff;
}

.form-wrapper .MuiButton-root span {
  color: #ffffff;
}

.form-wrapper .edit-widget-button-array {
  text-align: right;
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin-bottom: 20px;
  margin-right: 20px;
}
.add-db-preview {
  text-align: center;
}
.preview-image {
  width: 320px;

    padding: 5px;
}
.form-wrapper .github-picker {
  background-color: transparent !important;
  width: 100%;
  padding-left: 5px;
}

.radio-item {
  display: inline-block;
}
