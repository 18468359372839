@import "./methods.scss";

html,
body {
  height: 100%;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  /*min-width: 1280px;
    min-height: 720px;*/
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  .page-title {
    font-size: 1.75em;
    margin-top: 3%;
    margin-left: 30%;
  }

  .page-title2 {
    margin-top: 2%;
    font-family: $font;
    font-size: 24px;
    font-weight: $cwsemibold;
    margin-left: 5px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    text-align:center;
  }
}

.container,
#root {
  position: relative;
  float: left;
  height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
.container {
  background-image: url("../css/images/SydneyCloud.jpeg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.arrow-cloud {
  background-image: url("../css/images/chinesecloud.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.page {
  position: relative;
  float: left;
  height: 100vh;
  max-width: calc(100% - 3.5vw);
  width: 100%;
  overflow-x: hidden;
  background-color: #F2F2F2;
}

.lt {
  background: $lt_background;

  .sidebar {
    background: $cwblack;
  }
}

//logo
.LogoSidebar {
  -webkit-animation-name: spinner;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 6s;
  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 6s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  text-align: center;
}

/* WebKit and Opera browsers */
@-webkit-keyframes spinner {
  from {
    -webkit-transform: rotateY(0deg);
  }

  to {
    -webkit-transform: rotateY(-360deg);
  }
}
/* all other browsers */
@keyframes spinner {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  to {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
}

//custome CSS
.TileBorder {
  border: 1px solid lightgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.sidebar .dashboard-icon {
  text-align: center;
  margin-top: 15px !important;
  padding: 20px;
  width: 80px;

  margin: auto;
  /* margin: 0 auto !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 12px;

}
.sidebar .dashboard-icon:hover {
  border: 1px solid grey;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
.dashboard-text {
  font-size: 14px;
  color: #252423;
  padding-top: 15px;
}
.LogoSidebar {
  margin: 0 auto;
}
.dashboard-text:hover {
  text-decoration: none;
}


.component-title {
  margin-top: 2%;
  font-family: $font;
  font-size: 24px;
  font-weight: $cwsemibold;
  margin-left: 5px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  text-align:center;
  display: none;
}
.component-title-second {
  font-family: $font;
  font-size: 20px;
  font-weight: $cwregular;
  text-transform: uppercase;
      text-align: left;
}
.content-wrapper {
  padding: 20px;
  height: 100%;
}
.statistics2 {
  height: 150px;
  font-size: 70px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 0px;
  padding: 20px;
  position: relative;

  .info {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
  }
}

.medium .statistics2 {
  font-size: 30px;

}
.large .statistics2 {
  font-size: 70px;
}

.floating-data {
  position: absolute;
    bottom: 0;
    font-size: 40px !important;
}
.medium .floating-data {

    font-size: 20px !important;
}
.large .floating-data {

    font-size: 40px !important;
}

.statistics3 {
  height: 30px;
  font-size: 20px;
  font-weight: $cwlight;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 0px;
}
.medium .statistics3 {
  font-size: 14px;

}
.large .statistics3 {
  font-size: 20px;

}

.loader {
  position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(213,213,213,0.8);
    z-index: 100;

}

.loader-image  {
    position: absolute;
    width: 100%;
    top: 25%;
    text-align: center;

}

.loader-image  img{

    height: 15%;
    width: 15%;

}


.laoder-text {
  color: $cwpurple;
  font-size: 20px;
  text-align: center;
  background-color: white;
  width: 15%;
  margin: 0 auto;
}

.pop-addDB {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.form-wrapper {
  width: 60%;
  height: 60%;
  margin: 0 auto;
  background-color: white;
  color: white;
  margin-top: 10%;
  padding: 20px;
}

.MuiFormControl-root {
  color: white;
  width: 80%;
  padding-left: 10%;
}

.pop-select-widget {
  position: absolute;
  top: 159px;
  right: 0;
  width: 200px;
  background-color: black;
  color: white;
  z-index: 1;
  height: 300px;
  overflow: scroll;
}
.pop-select-list {
  overflow: scroll;
}

.widget-menu-icon {
  position: absolute;
right: 0;
}

.threshold-warning-flasher {
  animation: warningflasher 3s linear infinite !important;
}

@keyframes warningflasher {
  50% {
    background-color: orange;
  }
}

.threshold-alert-flasher {
  animation: alertflasher 3s linear infinite !important;
}

@keyframes alertflasher {
  50% {
    background-color: red;
  }
}
