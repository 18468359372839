@import "../../../methods.scss";

/* brand CSS only */

.widget-module.light .count-number {
  color: $cwblack;
}

.widget-module.dark .count-number {
  color: $cwwhite;
}

.widget-module.light .count-queue {
  color: $cwblack;
}

.widget-module.dark .count-queue {
  color: $cwwhite;
}

.widget-module.light .grid-item h2 {
  color: $cwblack;
}

.widget-module.dark .grid-item h2 {
  color: $cwwhite;
}
.widget-module.light svg {
  color: $cwblack;
}

.widget-module.dark svg {
  color: $cwwhite;
}

.widget-module.light {
  background-color: $cwwhite;
}

.widget-module.dark  {
  background-color: $cwblack;
}


.count-number {
  font-family: $font;
  font-size: 72px;
}

.count-queue {
  font-family: $font;
  font-size: 10px;

}

.react-grid-item h2 {
  font-family: $font;
  font-size: 16px;

}
