@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap);
::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

html,
body {
  height: 100%;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  /*min-width: 1280px;
    min-height: 720px;*/
  font-family: "Roboto", "Helvetica", "Arial", sans-serif; }
  html .page-title,
  body .page-title {
    font-size: 1.75em;
    margin-top: 3%;
    margin-left: 30%; }
  html .page-title2,
  body .page-title2 {
    margin-top: 2%;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: 600;
    margin-left: 5px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    text-align: center; }

.container,
#root {
  position: relative;
  float: left;
  height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0 !important;
  padding: 0 !important; }

.container {
  background-image: url(/static/media/SydneyCloud.83b6f55e.jpeg);
  background-repeat: no-repeat;
  background-size: 100% 100%; }

.arrow-cloud {
  background-image: url(/static/media/chinesecloud.2578f3cb.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%; }

.page {
  position: relative;
  float: left;
  height: 100vh;
  max-width: calc(100% - 3.5vw);
  width: 100%;
  overflow-x: hidden;
  background-color: #F2F2F2; }

.lt {
  background: #f2f5f8; }
  .lt .sidebar {
    background: #000000; }

.LogoSidebar {
  -webkit-animation-name: spinner;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 6s;
  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 6s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  text-align: center; }

/* WebKit and Opera browsers */
@-webkit-keyframes spinner {
  from {
    -webkit-transform: rotateY(0deg); }
  to {
    -webkit-transform: rotateY(-360deg); } }

/* all other browsers */
@keyframes spinner {
  from {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  to {
    -webkit-transform: rotateY(-360deg);
            transform: rotateY(-360deg); } }

.TileBorder {
  border: 1px solid lightgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1); }

.sidebar .dashboard-icon {
  text-align: center;
  margin-top: 15px !important;
  padding: 20px;
  width: 80px;
  margin: auto;
  /* margin: 0 auto !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 12px; }

.sidebar .dashboard-icon:hover {
  border: 1px solid grey;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  text-decoration: none; }

.dashboard-text {
  font-size: 14px;
  color: #252423;
  padding-top: 15px; }

.LogoSidebar {
  margin: 0 auto; }

.dashboard-text:hover {
  text-decoration: none; }

.component-title {
  margin-top: 2%;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin-left: 5px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  text-align: center;
  display: none; }

.component-title-second {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left; }

.content-wrapper {
  padding: 20px;
  height: 100%; }

.statistics2 {
  height: 150px;
  font-size: 70px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 0px;
  padding: 20px;
  position: relative; }
  .statistics2 .info {
    font-size: 16px;
    font-weight: 300;
    text-align: center; }

.medium .statistics2 {
  font-size: 30px; }

.large .statistics2 {
  font-size: 70px; }

.floating-data {
  position: absolute;
  bottom: 0;
  font-size: 40px !important; }

.medium .floating-data {
  font-size: 20px !important; }

.large .floating-data {
  font-size: 40px !important; }

.statistics3 {
  height: 30px;
  font-size: 20px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 0px; }

.medium .statistics3 {
  font-size: 14px; }

.large .statistics3 {
  font-size: 20px; }

.loader {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(213, 213, 213, 0.8);
  z-index: 100; }

.loader-image {
  position: absolute;
  width: 100%;
  top: 25%;
  text-align: center; }

.loader-image img {
  height: 15%;
  width: 15%; }

.laoder-text {
  color: #986fb0;
  font-size: 20px;
  text-align: center;
  background-color: white;
  width: 15%;
  margin: 0 auto; }

.pop-addDB {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1; }

.form-wrapper {
  width: 60%;
  height: 60%;
  margin: 0 auto;
  background-color: white;
  color: white;
  margin-top: 10%;
  padding: 20px; }

.MuiFormControl-root {
  color: white;
  width: 80%;
  padding-left: 10%; }

.pop-select-widget {
  position: absolute;
  top: 159px;
  right: 0;
  width: 200px;
  background-color: black;
  color: white;
  z-index: 1;
  height: 300px;
  overflow: scroll; }

.pop-select-list {
  overflow: scroll; }

.widget-menu-icon {
  position: absolute;
  right: 0; }

.threshold-warning-flasher {
  -webkit-animation: warningflasher 3s linear infinite !important;
          animation: warningflasher 3s linear infinite !important; }

@-webkit-keyframes warningflasher {
  50% {
    background-color: orange; } }

@keyframes warningflasher {
  50% {
    background-color: orange; } }

.threshold-alert-flasher {
  -webkit-animation: alertflasher 3s linear infinite !important;
          animation: alertflasher 3s linear infinite !important; }

@-webkit-keyframes alertflasher {
  50% {
    background-color: red; } }

@keyframes alertflasher {
  50% {
    background-color: red; } }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

iframe {
  width: 320px !important;
  height: 550px !important; }

.call-notes {
  margin-top: 24px !important;
  min-width: 500px !important; }

.campaign-select {
  margin-bottom: 24px !important; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

.layout {
  position: relative;
  float: left;
  height: 80vh;
  width: 90vw;
  max-height: 80vh;
  max-width: 90vw;
  overflow-x: hidden;
  overflow-y: scroll; }
  .layout .react-grid-item {
    position: relative;
    float: left;
    height: 100%;
    width: 100%;
    background: white;
    margin-bottom: 1.25vmax;
    border-radius: 0.3vmax;
    -ms-box-shadow: 0 0 1vmax 0.25vmax rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 1vmax 0.25vmax rgba(0, 0, 0, 0.1); }
    .layout .react-grid-item.react-draggable-dragging {
      background: white; }
    .layout .react-grid-item p {
      position: absolute;
      float: left;
      margin: 0;
      padding: 0;
      width: 80%;
      text-align: center;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word; }
  .layout .react-grid-placeholder {
    background: rgba(0, 0, 0, 0);
    border: dotted 0.25vmax rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

nav {
  position: fixed;
  height: 100%;
  width: 80px;
  left: 0;
  z-index: 1;
  background-color: black;
  padding-top: 30px;
  top: 0px; }

nav a {
  color: #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  margin-top: 15px !important;
  padding: 20px;
  width: 100%;
  display: block;
  margin: auto;
  /* margin: 0 auto !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 12px; }

.nav-active-1 {
  background-color: #986fb0; }

.nav-active-2 {
  background-color: #ffdd19; }

.nav-active-3 {
  background-color: #00afe9; }

.nav-active-4 {
  background-color: #ed3c80; }

.nav-active-5 {
  background-color: #56b46a; }

.nav-active-6 {
  background-color: #56b46a; }

.sidebar {
  /*position: relative;
    float: left;
    height: 100vh;
    width: 3.5vw;
    min-width: 45px;
    background: $lt_panel;*/
  height: 100%;
  width: 100%; }
  .sidebar .logo {
    position: relative;
    float: left;
    width: 80%;
    height: auto; }
    .sidebar .logo img {
      position: absolute;
      float: left;
      height: 80%;
      width: 80%;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
  .sidebar .dashboard-icon {
    color: #ffffff;
    margin-left: 17px;
    margin-top: 15px;
    margin-bottom: 15px; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

.header {
  width: 100%;
  height: 100px;
  position: fixed;
  padding-left: 100px;
  background-color: #ffffff;
  border-bottom-color: grey;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  background-color: #3A404E;
  z-index: 1; }

.boardviewer .header {
  padding-left: 20px; }

.header h2 {
  font-size: 30px;
  color: #00afe9;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.header h3 {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.4em; }

.header-logo {
  height: 80px;
  padding-top: 20px; }

.header .image1 {
  padding-right: 20px;
  border-right-color: grey;
  border-right-style: solid;
  border-right-width: 1px; }

.header .image2 {
  padding-left: 20px; }

.header divider {
  height: 80px;
  width: 20px;
  border-left-color: black;
  border-left-width: 1px; }

.account-details {
  float: right;
  margin-right: 80px;
  height: 100%;
  padding-top: 25px;
  color: white; }

.account-details SVG {
  width: 40px;
  height: 40px;
  padding-top: 5px; }

.account-details ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.account-avatar {
  display: inline-block;
  padding-left: 5px;
  height: 60px; }

.account-name {
  display: inline-block;
  padding-left: 5px;
  line-height: 1em;
  font-family: "Montserrat", sans-serif;
  vertical-align: middle;
  height: 10px;
  letter-spacing: 3px; }

.account-details .MuiPaper-root {
  width: 11%; }

.account-avatar img {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  vertical-align: middle;
  margin-right: 10px; }

.boardviewer .update-time {
  position: fixed;
  top: 35px;
  color: white;
  font-family: "Montserrat", sans-serif;
  right: 20px; }

.update-time {
  position: fixed;
  top: 35px;
  color: white;
  font-family: "Montserrat", sans-serif;
  right: 35%; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

#fl-add {
  position: fixed;
  bottom: 10px;
  right: 30px;
  background-color: #f7921e;
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  color: #ffffff;
  font-weight: 600; }

#menuList {
  position: fixed;
  bottom: 70px;
  right: 5px; }

.menuItem {
  height: 100px;
  background-color: white !important;
  margin-bottom: 7px !important;
  border-color: grey !important;
  border-style: solid !important;
  border-width: 1px !important;
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.75) !important;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  color: #ffffff !important;
  font-weight: 300; }

.menuIcon {
  min-width: 30px !important;
  color: #ffffff !important; }

.active-1 {
  background-color: #986fb0 !important;
  opacity: 0.80; }

.active-2 {
  background-color: #ffdd19 !important;
  opacity: 0.80; }

.active-3 {
  background-color: #00afe9 !important;
  opacity: 0.80; }

.active-4 {
  background-color: #ed3c80 !important;
  opacity: 0.80; }

.active-5 {
  background-color: #56b46a !important;
  opacity: 0.80; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

.tool-bar {
  position: fixed;
  right: 0;
  top: 98px;
  background-color: white; }
  .tool-bar .tool {
    width: 100%;
    height: 60px;
    width: 60px;
    padding-top: 12px;
    padding-left: 18px;
    position: absolute;
    right: 0px; }
  .tool-bar .tool1 {
    background-color: #f7921e;
    color: white; }
  .tool-bar .tool-open {
    opacity: 0.5; }

.connector {
  position: fixed;
  right: 0;
  top: 98px;
  width: 320px;
  height: 550px;
  margin-right: 60px;
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.75) !important;
  background-color: #ffffff; }

.react-grid-layout {
  position: relative;
  -webkit-transition: height 200ms ease;
  transition: height 200ms ease; }

.react-grid-item {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  -webkit-transition-property: left, top;
  transition-property: left, top; }

.react-grid-item.cssTransforms {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height; }

.react-grid-item.react-draggable-dragging {
  -webkit-transition: none;
  transition: none;
  z-index: 3;
  will-change: transform; }

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  -webkit-transition-duration: 100ms;
          transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; }

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize; }

.react-grid-item > .react-resizable-handle::after {
  content: '';
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4); }

.react-resizable {
  position: relative; }

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0; }

.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize; }

.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize; }

.react-resizable-handle-w {
  left: 0;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg); }

.react-resizable-handle-e {
  right: 0;
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg); }

.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize; }

.react-resizable-handle-n {
  top: 0;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg); }

.react-resizable-handle-s {
  bottom: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

.TileBorder {
  width: 100%;
  height: 100%; }

.TileContent {
  width: 100%;
  height: 100%; }

.react-grid-item {
  border: 1px solid grey;
  overflow: auto;
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.75) !important;
  background-color: #ffffff; }

.db-container {
  position: relative;
  float: left;
  height: 100%;
  width: 100%; }

.grid-wrapper .header {
  display: none; }

.grid-wrapper .component-title {
  display: block; }

.sf_iframe {
  border: none !important;
  width: 44vw !important; }

.connect-container {
  position: relative;
  float: left;
  height: 100%;
  width: 100%; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

.change-dashboard .MuiSelect-root {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 3px; }

.change-dashboard .MuiInput-underline::before {
  border-bottom: 0px; }

.change-dashboard .MuiInputBase-input {
  border-bottom-color: white !important;
  margin-right: 20px; }

.change-dashboard svg {
  color: white !important;
  height: 30px;
  width: 30px;
  margin-top: -4px;
  margin-left: 10px; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

.action-menu {
  position: fixed;
  right: 0px;
  top: 160px;
  z-index: 1000;
  width: 60px; }

.action-menu .MuiButton-contained {
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0), 0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 3px 1px -2px rgba(0, 0, 0, 0); }

#customized-menu .MuiMenu-paper {
  background-color: #3a404e;
  color: white; }

#customized-menu .MuiListItem-button {
  height: 30px;
  min-height: 30px;
  font-size: 14px; }

#customized-menu .MuiMenu-paper svg {
  color: white;
  position: absolute;
  right: 11px;
  top: 8px;
  height: 15px; }

.MuiTypography-body1 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

.form-wrapper {
  background-color: #3a404e;
  padding: 50px;
  position: relative;
  font-family: "Montserrat", sans-serif;
  border-radius: 15px; }

.form-wrapper h2 {
  width: 100%;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 600; }

.form-wrapper input {
  background-color: #3a404e;
  color: white;
  font-family: "Montserrat", sans-serif; }

.form-wrapper .MuiFormLabel-root {
  font-family: "Montserrat", sans-serif; }

.form-wrapper .MuiButtonBase-root {
  font-family: "Montserrat", sans-serif; }

.form-wrapper .MuiInput-underline:after {
  border-bottom: 0px;
  color: white; }

.form-wrapper .MuiInputLabel-root {
  z-index: 1;
  color: white; }

.add-db-item {
  margin-top: 20px;
  margin-bottom: 20px; }

.form-wrapper .Mui-focused {
  color: white !important; }

.form-wrapper .MuiButton-root span {
  color: #ffffff; }

.form-wrapper .MuiButton-root span {
  color: #ffffff; }

.form-wrapper .edit-widget-button-array {
  text-align: right;
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin-bottom: 20px;
  margin-right: 20px; }

.add-db-preview {
  text-align: center; }

.preview-image {
  width: 320px;
  padding: 5px; }

.form-wrapper .github-picker {
  background-color: transparent !important;
  width: 100%;
  padding-left: 5px; }

.radio-item {
  display: inline-block; }

.select-search {
  color: white;
  position: fixed;
  background-color: #000000;
  z-index: 1;
  font-size: 10px; }

.select-search svg {
  color: white; }

.select-search input {
  color: white;
  font-size: 12px; }

.select-search .MuiInput-underline:after {
  border: 0; }

.pop-select-list {
  margin-top: 30px; }

.pop-select-item {
  text-align: left !important;
  width: 100%; }

.pop-select-item .MuiButton-label {
  color: white;
  text-align: left !important;
  font-size: 10px;
  width: 100%;
  justify-content: flex-start; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

.edit-widget .MuiPaper-root {
  background-color: black;
  color: white;
  width: 250px;
  padding: 10px; }

.edit-widget .MuiPaper-root svg {
  color: white; }

.edit-widget .MuiPaper-root .MuiInputLabel-root {
  color: white; }

.edit-widget .MuiPaper-root input {
  background-color: #3a404e;
  color: white; }

.edit-widget .MuiPaper-root .MuiSelect-selectMenu {
  background-color: black;
  color: white;
  width: 80%;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase; }

.edit-widget.github-picker {
  background-color: #3a404e; }

.edit-widget-item {
  margin-top: 5px;
  margin-bottom: 15px;
  margin-left: 5px; }

.edit-widget-label {
  font-size: 12px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em; }

.edit-widget-item .github-picker {
  background-color: #000000 !important;
  width: 100%;
  padding-left: 5px; }

.edit-widget .MuiButton-root span {
  color: #9b9b9b; }

.edit-widget-button-array {
  text-align: right; }

.widget-menu-icon {
  background-color: transparent;
  border-color: transparent; }

.action-buttons {
  position: absolute;
  top: 5px;
  right: 5px; }

.edit-widget-split-left {
  display: inline-block;
  width: 110px; }

.edit-widget-split-right {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  width: 50px; }

.edit-widget-item input {
  text-align: center; }

.edit-widget-item .Mui-checked + .MuiSwitch-track {
  background-color: green !important; }

.edit-widget-item .MuiSwitch-track {
  background-color: white; }

.edit-widget-item .Mui-checked .MuiSwitch-thumb {
  background-color: green; }

.edit-widget-item .MuiSwitch-thumb {
  background-color: red; }

.inline-selector .MuiSelect-select {
  font-size: 10px; }

.edit-widget-item .Mui-disabled {
  opacity: 0.5; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

.dashboard-lock {
  position: absolute;
  top: 35px;
  right: 30px;
  color: #56b46a; }

.dashboard-lock svg {
  height: 30px;
  width: 30px; }

.share-link {
  float: left; }

.share-button-array {
  float: right; }

.share-button-array button {
  background-color: green; }

.share-button-array .MuiButton-label {
  color: white !important; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

.TileBorder {
  width: 100%;
  height: 100%; }

.TileContent {
  width: 100%;
  height: 100%; }

.react-grid-item {
  border: 1px solid grey;
  overflow: auto;
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0) !important;
  background-color: #ffffff;
  border-radius: 10px; }

.db-container {
  position: relative;
  float: left;
  height: 100%;
  width: 100%; }

.grid-wrapper .header {
  display: none; }

.grid-wrapper .component-title {
  display: block; }

.change-dashboard {
  position: fixed;
  top: 30px;
  right: 50%; }

.content-wrapper-td {
  padding-top: 120px;
  padding-left: 80px;
  min-height: 100%; }

.timer-bar {
  height: 5px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%; }

.timer-bar-inner {
  height: 5px;
  width: 0px;
  background-color: red; }

.boardviewer .content-wrapper-td {
  padding-left: 10px; }

/* structural CSS only */
.react-grid-item h2 {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%; }

.count-number {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center; }

.count-queue {
  padding-left: 10px; }

.react-grid-item[max-height~="1800px"] .count-number {
  font-size: 700px; }

.react-grid-item[max-height~="1800px"] h2 {
  font-size: 50px; }

.react-grid-item[max-height~="1500px"] .count-number {
  font-size: 600px; }

.react-grid-item[max-height~="1200px"] .count-number {
  font-size: 500px; }

.react-grid-item[max-height~="1200px"] h2 {
  font-size: 32px; }

.react-grid-item[max-height~="900px"] .count-number {
  font-size: 300px; }

.react-grid-item[max-height~="600px"] .count-number {
  font-size: 150px; }

.react-grid-item[max-height~="600px"] h2 {
  font-size: 22px; }

.react-grid-item[max-height~="300px"] .count-number {
  font-size: 100px;
  height: 60%; }

.react-grid-item[max-height~="300px"] h2 {
  font-size: 10px; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

/* brand CSS only */
.widget-module.light .count-number {
  color: #000000; }

.widget-module.dark .count-number {
  color: #ffffff; }

.widget-module.light .count-queue {
  color: #000000; }

.widget-module.dark .count-queue {
  color: #ffffff; }

.widget-module.light .grid-item h2 {
  color: #000000; }

.widget-module.dark .grid-item h2 {
  color: #ffffff; }

.widget-module.light svg {
  color: #000000; }

.widget-module.dark svg {
  color: #ffffff; }

.widget-module.light {
  background-color: #ffffff; }

.widget-module.dark {
  background-color: #000000; }

.count-number {
  font-family: "Montserrat", sans-serif;
  font-size: 72px; }

.count-queue {
  font-family: "Montserrat", sans-serif;
  font-size: 10px; }

.react-grid-item h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 16px; }

.chart-internal {
  position: absolute;
  top: 25%;
  width: 100%; }

.line-internal {
  position: absolute;
  width: 95%;
  margin-left: 2%; }

.line-item {
  height: 100%; }

.react-grid-item[max-height~="1800px"] .line-internal {
  height: 92%; }

.react-grid-item[max-height~="1500px"] .line-internal {
  height: 92%; }

.react-grid-item[max-height~="1200px"] .line-internal {
  height: 92%; }

.react-grid-item[max-height~="900px"] .line-internal {
  height: 90%; }

.react-grid-item[max-height~="600px"] .line-internal {
  height: 85%; }

.react-grid-item[max-height~="300px"] .line-internal {
  height: 75%; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

.widget-module.light .line-item h2 {
  color: #000000; }

.widget-module.dark .line-item h2 {
  color: #ffffff; }

.widget-module.light {
  background-color: #ffffff; }

.widget-module.dark {
  background-color: #000000; }

.bar-back {
  position: absolute;
  top: 40%;
  width: 80%;
  background-color: blue;
  margin-left: 10%; }

.bar-front {
  background-color: red;
  text-align: center; }

.dial-internal {
  position: absolute;
  top: 10%;
  width: 90%;
  left: 5%; }

/* structural CSS only */
.react-grid-item h2 {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%; }

.timefmt-number {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center; }

.timefmt-queue {
  padding-left: 5px; }

.react-grid-item[max-height~="1800px"] .timefmt-number {
  font-size: 400px; }

.react-grid-item[max-height~="1800px"] h2 {
  font-size: 50px; }

.react-grid-item[max-height~="1500px"] .timefmt-number {
  font-size: 300px; }

.react-grid-item[max-height~="1200px"] .timefmt-number {
  font-size: 200px; }

.react-grid-item[max-height~="1200px"] h2 {
  font-size: 32px; }

.react-grid-item[max-height~="900px"] .timefmt-number {
  font-size: 100px; }

.react-grid-item[max-height~="400px"] .timefmt-number {
  font-size: 70px; }

.react-grid-item[max-width~="400px"] .timefmt-number {
  font-size: 70px; }

.react-grid-item[max-height~="400px"] h2 {
  font-size: 20px; }

.react-grid-item[max-height~="300px"] .timefmt-number {
  font-size: 50px; }

.react-grid-item[max-width~="300px"] .timefmt-number {
  font-size: 50px; }

.react-grid-item[max-height~="300px"] h2 {
  font-size: 15px; }

.react-grid-item[max-height~="200px"] .timefmt-number {
  font-size: 40px; }

.react-grid-item[max-width~="200px"] .timefmt-number {
  font-size: 40px; }

.react-grid-item[max-height~="200px"] h2 {
  font-size: 10px; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

/* brand CSS only */
.widget-module.light .timefmt-number {
  color: #000000; }

.widget-module.dark .timefmt-number {
  color: #ffffff; }

.widget-module.light .timefmt-queue {
  color: #000000; }

.widget-module.dark .timefmt-queue {
  color: #ffffff; }

.widget-module.light .grid-item h2 {
  color: #000000; }

.widget-module.dark .grid-item h2 {
  color: #ffffff; }

.widget-module.light svg {
  color: #000000; }

.widget-module.dark svg {
  color: #ffffff; }

.widget-module.light {
  background-color: #ffffff; }

.widget-module.dark {
  background-color: #000000; }

.timefmt-number {
  font-family: "Montserrat", sans-serif;
  font-size: 72px; }

.timefmt-queue {
  font-family: "Montserrat", sans-serif;
  font-size: 10px; }

.react-grid-item h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 16px; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

tbody tr:hover {
  background-color: #00afe9 !important; }

.dn {
  display: none; }

.hidEl {
  opacity: 0;
  pointer-events: none; }

.table-tab__wrap {
  display: flex;
  margin-top: 10px;
  margin-bottom: 25px;
  padding: 0 10px; }

.table-tab {
  padding: 10px 15px;
  background: #e8e5e5;
  border-radius: 5px 0 0 5px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: 0.4s opacity;
  transition: 0.4s opacity; }
  .table-tab:last-of-type {
    border-radius: 0 5px 5px 0; }
  .table-tab:hover {
    opacity: 0.8; }
  .table-tab.active {
    background-color: #c3c2c2; }

.tale-bl-head {
  text-align: center;
  padding: 20px 10px 0; }

.statusbar {
  height: 130px; }

.contactheader {
  display: flex;
  flex-direction: row;
  height: 70px;
  justify-content: space-between; }

.bl-agent-data {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.tale-bl-head__data {
  display: inline-block; }

.table-header-image {
  display: inline-block;
  height: 70px; }

.bl-agent-data__item-name {
  padding-left: 30px;
  font-weight: bold; }

.tale-bl-head__title {
  font-size: 35px;
  font-weight: bold; }

.tale-bl-head__logo {
  width: 180px;
  height: 110px;
  background-image: url(/static/media/stockland.9db48920.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain; }

.table-manag__wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; }

.btn_table {
  min-height: 40px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 5px 10px;
  color: #ffffff;
  background: #c3c2c2;
  border-radius: 5px;
  -webkit-transition: 0.4s opacity;
  transition: 0.4s opacity;
  cursor: pointer;
  border: none;
  outline: none; }
  .btn_table:hover {
    opacity: 0.8; }

.form-title {
  margin-bottom: 30px;
  padding-top: 20px; }

.form-wrap__radio {
  margin-bottom: 30px; }

.form__lab {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-width: 150px; }
  .form__lab:last-of-type {
    margin-bottom: 0; }

.form-lab__mark {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  border: 2px solid gray;
  margin-right: 20px; }
  .form-lab__mark::before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #1875d2;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform: scale(0);
            transform: scale(0); }

.form-lab__inp:checked + .form-lab__mark {
  border: 2px solid #1875d2; }
  .form-lab__inp:checked + .form-lab__mark::before {
    -webkit-transform: scale(1);
            transform: scale(1); }

.form-lab__text {
  display: block;
  max-width: 200px;
  cursor: pointer; }
  .form-lab__text:hover {
    opacity: 0.8; }

.form-wrap__field {
  margin-bottom: 40px; }

.form-field-cont {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 25px; }
  .form-field-cont:last-of-type {
    margin-bottom: 0; }

.form-field-cont__text {
  max-width: 150px;
  width: 100%;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: bold; }

.form-field__inp {
  width: 220px;
  padding: 3px 5px;
  border: 1px solid #8e8b8b; }

.form-field__valid {
  font-size: 11px;
  color: rgba(234, 75, 75, 0.8);
  position: absolute;
  z-index: 3;
  left: 152px;
  bottom: -15px;
  -webkit-transition: 0.4s opacity;
  transition: 0.4s opacity;
  padding: 0;
  margin: 0;
  pointer-events: none;
  opacity: 0; }

.field_empty .form-field__valid_empty {
  opacity: 1; }

.field_min .form-field__valid_min {
  opacity: 1; }

.field_numb .form-field__valid_numb {
  opacity: 1; }

.field_empty .form-field__inp,
.field_min .form-field__inp,
.field_numb .form-field__inp {
  border: 1px solid rgba(234, 75, 75, 0.8); }

.form-wrap__text {
  position: relative;
  margin-bottom: 60px; }

.form-text__alert {
  font-size: 14px; }

.form-text__desc {
  font-size: 14px;
  color: #929090; }

.add-cont__mes {
  position: absolute;
  bottom: -50px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  opacity: 0;
  -webkit-transition: 0.4s opacity;
  transition: 0.4s opacity;
  margin: 0; }

.add-cont__mes_suc {
  opacity: 1;
  color: lime; }

.add-cont__mes_val {
  opacity: 1;
  color: red; }

.form-wrap__btn {
  display: flex;
  justify-content: center; }

.btn_form {
  min-height: 40px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 5px 10px;
  color: #ffffff;
  background: #c3c2c2;
  border-radius: 5px;
  -webkit-transition: 0.4s opacity;
  transition: 0.4s opacity;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 0 20px; }
  .btn_form:hover {
    opacity: 0.8; }

.btn-primary {
  background: #385fdd; }

.btn-danger {
  background: #DC1B4E; }

.image1 {
  height: 70px; }

.tabs-left {
  justify-content: space-between; }

.tabs-right {
  justify-content: space-between; }

.searchbar-wrapper {
  background-color: #E4E4E4 !important;
  width: 100%;
  box-shadow: 0px 0px 0px 0px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

.searchbar-textfield {
  display: inline-block;
  width: 80%;
  padding-top: 5px;
  margin-left: 10px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300; }

.searchbutton {
  min-width: 45px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: #373737; }

.table-manag__wrap svg {
  margin-left: 15px;
  color: #999999; }

.tbl-row {
  background-color: #373737; }

.MuiTableRow-root.Mui-selected {
  background-color: #373737 !important;
  opacity: 0.80; }

.tbl-cell {
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  font-size: 14px !important;
  padding: 0px !important; }

.tbl-cell-cb {
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  font-size: 14px;
  padding: 0px !important; }

.tbl-cell-cb svg {
  color: white; }

.call-available {
  color: #56b46a !important; }

.call-unavailable {
  color: #ed3c80 !important; }

.table-big-wrapper {
  clear: both; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

.tbl-row {
  background-color: #373737;
  line-height: 1.43; }

.tbl-cell {
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  font-size: 14px !important;
  padding: 0px !important; }

.tbl-cell-first {
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  font-size: 14px;
  padding: 5px,0px, 0px, 0px !important; }

.topoffset {
  padding-top: 20px; }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

* {
  outline: 0; }

.wb-container {
  position: relative;
  float: left;
  height: 100%;
  width: 100%; }
  .wb-container .stat-1 {
    background-color: #999999;
    box-shadow: 0px 0px 0px 0px; }
  .wb-container .stat-2 {
    background-color: #F2F2F2;
    box-shadow: 0px 0px 0px 0px; }
  .wb-container header {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important; }
  .wb-container .MuiTabs-root {
    background: #f7f7ff;
    color: #385fdd; }
  .wb-container .statistics-container {
    box-sizing: border-box;
    margin: 10px;
    padding: 15px;
    box-shadow: 0px 0px 0px 0px;
    text-align: center; }
    .wb-container .statistics-container .statistics1 {
      height: 30px;
      width: 100%;
      padding-left: 20px;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 35px;
      align-items: center;
      box-shadow: 0px 0px 0px 0px; }
    .wb-container .statistics-container .green {
      color: green; }

.change-button {
  display: inline;
  float: right;
  background-color: lightgrey;
  color: white !important; }

@media only screen and (max-width: 671px) {
  .statistics3 {
    font-size: 12px !important; }
  .statistics2 {
    font-size: 40px !important; } }

::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar-track-piece {
  display: none; }

::-webkit-scrollbar-thumb {
  display: none; }

::-webkit-scrollbar-corner {
  display: none; }

::-webkit-resizer {
  display: none; }

.settings-header {
  padding-left: 20px;
  font-weight: bold !important; }

.settings-icon {
  margin-right: 5px;
  color: black !important; }

.settings-list {
  overflow-y: auto;
  height: 100vh;
  background-color: #FFFFFF; }

.settings-text {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-size: 13px !important;
  color: #545b64 !important;
  font-weight: 550 !important;
  line-height: 20px !important;
  letter-spacing: 0.8px !important; }

.admin-form {
  position: fixed;
  margin: auto;
  left: 20%;
  top: 20%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 90vw; }

.content-wrapper-td {
  padding-top: 100px;
  padding-left: 80px;
  min-height: 100%; }

.settings-paper {
  position: fixed !important;
  width: 15%; }

.settings-component {
  height: 100px !important;
  overflow-y: scroll !important; }

/**
 * The CSS in this file is for styling the demo page,
 * Meny's critical styles (such as transforms) are applied
 * via JavaScript.
 *
 * See the documentation here: https://github.com/hakimel/meny#meny
 *
 * @author Hakim El Hattab | http://hakim.se
 */
* {
  margin: 0;
  padding: 0; }

html,
body {
  height: 100%;
  overflow: hidden; }

body {
  background-color: #222;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGklEQVQIW2NkYGD4D8SMQAwGcAY2AbBKDBUAVuYCBQPd34sAAAAASUVORK5CYII=);
  background-repeat: repeat;
  font-family: 'Lato', Helvetica, sans-serif;
  font-size: 16px;
  color: #222; }

a {
  color: #c2575b;
  text-decoration: none;
  -webkit-transition: 0.15s color ease;
  transition: 0.15s color ease; }

a:hover {
  color: #f76f76; }

h1,
h2 {
  font-size: 24px; }

.meny {
  display: none;
  padding: 20px;
  overflow: auto;
  background: #333;
  color: #eee;
  box-sizing: border-box; }

.meny ul {
  margin-top: 10px; }

.meny ul li {
  display: inline-block;
  width: 200px;
  list-style: none;
  font-size: 20px;
  padding: 3px 10px; }

.meny ul li:before {
  content: '-';
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.2); }

/**
 * Hint graphic that appears while menu is inactive
 */
.meny-arrow {
  position: absolute;
  z-index: 10;
  border: 10px solid transparent;
  -webkit-transition: opacity 0.4s ease 0.4s;
  transition: opacity 0.4s ease 0.4s; }

.meny-left .meny-arrow {
  left: 14px;
  top: 50%;
  margin-top: -16px;
  border-left: 16px solid #333; }

.meny-right .meny-arrow {
  right: 14px;
  top: 50%;
  margin-top: -16px;
  border-right: 16px solid #333; }

.meny-top .meny-arrow {
  left: 50%;
  top: 14px;
  margin-left: -16px;
  border-top: 16px solid #333; }

.meny-bottom .meny-arrow {
  left: 50%;
  bottom: 14px;
  margin-left: -16px;
  border-bottom: 16px solid #333; }

.meny-active .meny-arrow {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease; }

/**
 * Main contents area
 */
.contents {
  background: #eee;
  padding: 20px 40px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transform-style: preserve-3d;
  box-sizing: border-box; }

.contents > article {
  max-width: 400px; }

.contents p {
  margin: 10px 0 10px 0;
  font-size: 16px;
  line-height: 1.32; }

.contents small {
  display: block;
  margin-top: 10px;
  padding-top: 10px;
  color: #333;
  font-size: 0.85em;
  border-top: 1px dashed #ccc;
  -webkit-text-size-adjust: none; }

.contents .sharing {
  position: absolute;
  bottom: 20px; }

.contents {
  padding: 0px 0px 0px 0px !important; }

.page {
  max-width: none !important; }

.meny {
  background: #F2F5F8; }

