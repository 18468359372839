@import '../../../css/methods.scss';

.layout {
  position: relative;
  float: left;
  height: 80vh;
  width: 90vw;
  max-height: 80vh;
  max-width: 90vw;

  overflow-x: hidden;
  overflow-y: scroll;

  // left: calc(50vw + 3.5vw) !important;
  // @include tf-xy;

  // top: 50%;
  // left: 50%;
  // -webkit-transform: translate(-50%, -50%);
  // -moz-transform: translate(-50%, -50%);
  // -ms-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);

  .react-grid-item {
    position: relative;
    float: left;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 1);
    margin-bottom: 1.25vmax;

    &.react-draggable-dragging {
      background: rgba(255, 255, 255, 1);
      //border: solid 0.25vmax $blue;
      //border: dotted 0.1vmax, rgba(0, 0, 0, 1);
    }

    p {
      @include textCenter;
    }

    @include border-radius(0.3vmax);
    @include box-shadow(0, 0, 1vmax, 0.25vmax, rgba(0, 0, 0, 0.1));
  }

  .react-grid-placeholder {
    background: rgba(0, 0, 0, 0);
    border: dotted 0.25vmax rgba(0, 0, 0, 0.1);
    @include box-shadow(0, 0, 0, 0, rgba(0, 0, 0, 0));
  }
}
