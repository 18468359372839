.line-internal {
  position: absolute;

  width:95%;
  margin-left: 2%;

}
.line-item {
  height: 100%;
}

.react-grid-item[max-height~="1800px"] .line-internal {
  height: 92%;

}

.react-grid-item[max-height~="1500px"] .line-internal {
  height: 92%;

}

.react-grid-item[max-height~="1200px"] .line-internal {
  height: 92%;

}


.react-grid-item[max-height~="900px"] .line-internal {
  height: 90%;

}

.react-grid-item[max-height~="600px"] .line-internal {
  height: 85%;

}

.react-grid-item[max-height~="300px"] .line-internal {
  height: 75%;

}
