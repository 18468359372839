@import '../../../css/methods.scss';

.header {
  width: 100%;
  height: 100px;
  position: fixed;
  padding-left: 100px;
  background-color: #ffffff;
  border-bottom-color: grey;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  background-color:#3A404E;
  z-index: 1;
}

.boardviewer .header {
  padding-left: 20px;
}

.header h2 {
  font-size: 30px;
  color: $cwblue;
  font-weight: $cwbold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.header h3 {
  font-size: 12px;
  font-weight: $cwlight;
  letter-spacing: 0.4em;
}


.header-logo {
  height: 80px;
  padding-top:20px;
}


.header .image1 {
  padding-right:20px;
  border-right-color: grey;
  border-right-style: solid;
  border-right-width: 1px;
}
.header .image2 {
  padding-left:20px;

}
.header divider {
  height: 80px;
  width: 20px;
  border-left-color: black;
  border-left-width: 1px;
}

.account-details {
    float: right;
    margin-right: 80px;
    height: 100%;
    padding-top:25px;
    color: white;
}


.account-details SVG {
  width: 40px;
  height: 40px;
      padding-top: 5px;
}

.account-details ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.account-avatar {
  display: inline-block;
  padding-left:5px;
  height: 60px;
}

.account-name {
  display: inline-block;
  padding-left:5px;
  line-height: 1em;
  font-family: $font;
  vertical-align: middle;
    height: 10px;
    letter-spacing: 3px;
}


.account-details .MuiPaper-root {
  width: 11%;
}




.account-avatar img {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  vertical-align: middle;
  margin-right: 10px;
}
.boardviewer .update-time {
  position: fixed;
  top: 35px;
  color: white;
  font-family: $font;
  right: 20px;
}

.update-time {
  position: fixed;
  top: 35px;
  color: white;
  font-family: $font;
  right: 35%;
}
