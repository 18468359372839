@import '../../../css/methods.scss';

@import '../../../css/react-grid-layout/css/styles.css';
@import '../../../css/react-resizable/css/styles.css';

tbody tr:hover {
  background-color: $cwblue !important;
}


.dn {
  display: none;
}

.hidEl {
  opacity: 0;
  pointer-events: none;
}

.table-tab__wrap {
  display: flex;
  margin-top: 10px;
  margin-bottom: 25px;
  padding: 0 10px;
}

.table-tab {
  padding: 10px 15px;
  background: #e8e5e5;
  border-radius: 5px 0 0 5px;
  text-align: center;
  cursor: pointer;
  transition: 0.4s opacity;

  &:last-of-type {
    border-radius: 0 5px 5px 0;
  }

  &:hover {
    opacity: 0.8;
  }

  &.active {
    background-color: #c3c2c2;
  }
}

.tale-bl-head {
  text-align: center;
  padding: 20px 10px 0;

}
.statusbar {
height: 130px;
}
.contactheader {
  display: flex;
  flex-direction: row;
  height: 70px;
  justify-content: space-between;
}

.bl-agent-data {
  display: inline-block;
  font-family: $font;
  font-size: 14px;
  font-weight: $cwregular;
}
.tale-bl-head__data {
  display: inline-block;

}

.table-header-image {
  display: inline-block;
  height: 70px;
}


.bl-agent-data__item-name {
  padding-left: 30px;
  font-weight: bold;
}

.tale-bl-head__title {
  font-size: 35px;
  font-weight: bold;
}

.tale-bl-head__logo {
  width: 180px;
  height: 110px;
  // background-image: url('../../../css/images/amp_logo.jpg');
  background-image: url('../../../css/images/stockland.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.table-manag__wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn_table {
  min-height: 40px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 5px 10px;
  color: #ffffff;
  background: #c3c2c2;
  border-radius: 5px;
  transition: 0.4s opacity;
  cursor: pointer;
  border: none;
  outline: none;

  &:hover {
    opacity: 0.8;
  }
}

//=======================================================================
.form-title {
  margin-bottom: 30px;
  padding-top: 20px;
}

.form-wrap__radio {
  margin-bottom: 30px;
}


.form__lab {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-width: 150px;

  &:last-of-type {
    margin-bottom: 0;
  }
}


.form-lab__mark {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  border: 2px solid gray;
  margin-right: 20px;

  &::before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #1875d2;
    transition: 0.6s;
    transform: scale(0);
  }
}

.form-lab__inp:checked + .form-lab__mark {
  border: 2px solid #1875d2;

  &::before {
    transform: scale(1);
  }
}

.form-lab__text {
  display: block;
  max-width: 200px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}


.form-wrap__field {
  margin-bottom: 40px;
}

.form-field-cont {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.form-field-cont__text {
  max-width: 150px;
  width: 100%;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: bold;
}

.form-field__inp {
  width: 220px;
  padding: 3px 5px;
  border: 1px solid #8e8b8b;
}


.form-field__valid {
  font-size: 11px;
  color: rgba(234, 75, 75, 0.8);
  position: absolute;
  z-index: 3;
  left: 152px;
  bottom: -15px;
  transition: 0.4s opacity;
  padding: 0;
  margin: 0;
  pointer-events: none;
  opacity: 0;
}

.field_empty .form-field__valid_empty {
  opacity: 1;
}

.field_min .form-field__valid_min {
  opacity: 1;
}

.field_numb .form-field__valid_numb {
  opacity: 1;
}


.field_empty .form-field__inp,
.field_min .form-field__inp,
.field_numb .form-field__inp {
  border: 1px solid rgba(234, 75, 75, 0.8);
}


.form-wrap__text {
  position: relative;
  margin-bottom: 60px;
}

.form-text__alert {
  font-size: 14px;
}

.form-text__desc {
  font-size: 14px;
  color: #929090;
}


.add-cont__mes {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  opacity: 0;
  transition: 0.4s opacity;
  margin: 0;
}

.add-cont__mes_suc {
  opacity: 1;
  color: lime;
}

.add-cont__mes_val {
  opacity: 1;
  color: red;
}


.form-wrap__btn {
  display: flex;
  justify-content: center;
}

.btn_form {
  min-height: 40px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 5px 10px;
  color: #ffffff;
  background: #c3c2c2;
  border-radius: 5px;
  transition: 0.4s opacity;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 0 20px;

  &:hover {
    opacity: 0.8;
  }
}

.btn-primary {
  background: #385fdd;
}

.btn-danger {
  background: #DC1B4E;
}
.image1 {
  height: 70px;
}

.tabs-left {
  justify-content: space-between;



}
.tabs-right {
  justify-content: space-between;

}
.searchbar-wrapper {
  background-color: #E4E4E4 !important;
  width: 100%;
  box-shadow: 0px 0px 0px 0px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.searchbar-textfield {
  display: inline-block;
  width: 80%;
  padding-top: 5px;
  margin-left:10px;
  font-family: $font !important;
  font-weight: $cwlight;

}
.searchbutton {
  min-width:45px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: #373737;

}

.table-manag__wrap svg {
  margin-left: 15px;
  color:#999999;

}

.tbl-row {
  background-color: #373737;
}

.MuiTableRow-root.Mui-selected {
  background-color: #373737 !important;
  opacity: 0.80;

}


.tbl-cell {
  color: #ffffff !important;
  font-family: $font !important;
  font-weight: $cwregular;
  font-size: 14px !important;
  padding: 0px !important;

}
.tbl-cell-cb {
  color: #ffffff !important;
  font-family: $font !important;
  font-weight: $cwregular;
  font-size: 14px;
  padding: 0px !important;

}
.tbl-cell-cb svg {
  color: white;
}

.call-available {
  color: $cwgreen !important;
}
.call-unavailable {
  color: $cwpink !important;
}
.table-big-wrapper {
  clear: both;
}
