/* structural CSS only */

.react-grid-item h2 {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}

.count-number {
    position: absolute;
      width: 100%;
      text-align: center;
      height: 80%;

      display: flex;
      align-items: center;
      justify-content: center;

}

.count-queue {
  padding-left: 10px;

}

.react-grid-item[max-height~="1800px"] .count-number {
font-size:700px;

}

.react-grid-item[max-height~="1800px"] h2 {
font-size:50px;

}

.react-grid-item[max-height~="1500px"] .count-number {
font-size:600px;

}

.react-grid-item[max-height~="1200px"] .count-number {
  font-size:500px;
}

.react-grid-item[max-height~="1200px"] h2 {
font-size:32px;

}


.react-grid-item[max-height~="900px"] .count-number {
font-size:300px;

}

.react-grid-item[max-height~="600px"] .count-number {
font-size:150px;

}

.react-grid-item[max-height~="600px"] h2 {
font-size:22px;

}

.react-grid-item[max-height~="300px"] .count-number {
font-size:100px;
height: 60%;

}

.react-grid-item[max-height~="300px"] h2 {
font-size:10px;


}
