@import "../../../methods.scss";

.edit-widget .MuiPaper-root{
  background-color: black;
  color: white;
  width: 250px;
  padding: 10px;
}

.edit-widget .MuiPaper-root svg {
    color: white;
}
.edit-widget .MuiPaper-root .MuiInputLabel-root {
    color: white;
}

.edit-widget .MuiPaper-root input {
    background-color: #3a404e;
    color: white;
}

.edit-widget .MuiPaper-root .MuiSelect-selectMenu {
    background-color: black;
    color: white;
    width: 80%;
    font-family: $font;
    text-transform: uppercase;
}

.edit-widget.github-picker {
  background-color: #3a404e;
}


.edit-widget-item {
  margin-top : 5px;
  margin-bottom : 15px;
  margin-left: 5px;
}
.edit-widget-label {

font-size: 12px !important;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
font-weight: 400;
line-height: 1;
letter-spacing: 0.00938em;
}
.edit-widget-item .github-picker {
  background-color: #000000 !important;
  width: 100%;
  padding-left: 5px;
}

.edit-widget .MuiButton-root span {
  color: #9b9b9b;

}

.edit-widget-button-array {
  text-align: right;
}

.widget-menu-icon {
  background-color: transparent;
  border-color: transparent;
}

.action-buttons {
  position: absolute;
  top: 5px;
  right: 5px;
}
.edit-widget-split-left {
  display: inline-block;
  width: 110px;
}
.edit-widget-split-right {
  display: inline-block;
  font-family: $font;
  font-size: 10px;
  width: 50px;
}
.edit-widget-item input {
  text-align: center
}



.edit-widget-item .Mui-checked + .MuiSwitch-track {
  background-color: green !important;
}

.edit-widget-item .MuiSwitch-track {
  background-color: white;
}

.edit-widget-item .Mui-checked .MuiSwitch-thumb {
  background-color: green;
}

.edit-widget-item .MuiSwitch-thumb {
  background-color: red;
}

.inline-selector .MuiSelect-select {
    font-size: 10px;
}

.edit-widget-item .Mui-disabled {
  opacity: 0.5;
}
