.bar-item {


}
.bar-back {
  position: absolute;
top: 40%;    
  width: 80%;
  background-color: blue;
  margin-left: 10%;
}
.bar-front {
  background-color: red;
  text-align: center;

}
