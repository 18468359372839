@import "../../../methods.scss";

.action-menu {
  position: fixed;
  right: 0px;
  top: 160px;
  z-index: 1000;
  width: 60px;
}

.action-menu .MuiButton-contained {
    background-color: transparent;
      border-color: transparent;
      box-shadow: 0px 1px 5px 0px rgba(0,0,0,0), 0px 2px 2px 0px rgba(0,0,0,0), 0px 3px 1px -2px rgba(0,0,0,0);
      
}

#customized-menu .MuiMenu-paper {
    background-color: #3a404e;
    color: white;
}

#customized-menu .MuiListItem-button {
    height: 30px;
    min-height: 30px;
    font-size: 14px;
}

#customized-menu .MuiMenu-paper svg{
    color: white;
    position: absolute;
    right: 11px;
    top: 8px;
    height: 15px;
}
.MuiTypography-body1  {
  font-family: $font !important;
    font-size: 14px !important;
}
