@import '../../../css/methods.scss';

#fl-add {

  position: fixed;
  bottom: 10px;
  right: 30px;
  background-color: $cworange;
  font-family: $font;
  font-size: 32px;
  color: #ffffff;
  font-weight: $cwsemibold;


}



#menuList {
  position: fixed;
  bottom: 70px;
  right: 5px;
}

.menuItem {
  height: 100px;
  background-color: white !important;
  margin-bottom: 7px !important;
  border-color: grey !important;
  border-style:solid !important;
  border-width: 1px !important;
  -webkit-box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75) !important;
-moz-box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75) !important;
box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75)!important;
font-family: $font;
font-size: 20px;
color: #ffffff !important;
font-weight: $cwlight;
}

.menuIcon {
  min-width: 30px !important;
  color: #ffffff !important
}
.active-1 {
  background-color: $cwpurple !important;
  opacity: 0.80;
}
.active-2 {
  background-color: $cwyellow !important;
  opacity: 0.80;
}

.active-3 {
  background-color: $cwblue !important;
  opacity: 0.80;
}

.active-4 {
  background-color: $cwpink !important;
  opacity: 0.80;
}

.active-5 {
  background-color: $cwgreen !important;
  opacity: 0.80;
}
