@import '../css/meny.css';

.contents {
    padding: 0px 0px 0px 0px !important;
}

.page {
    max-width: none !important;
}

.meny {
    background: #F2F5F8;
}
